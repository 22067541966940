import { CLIENT_NAMES } from "./clientsNames"

export const CLIENT_DICT = {
  74: CLIENT_NAMES.ECR,
  264: CLIENT_NAMES.GOCAR // real
  // 199: CLIENT_NAMES.GOCAR,  // para test (cliente krino)
}

export const getClientNameById = (clientId) => {
  return CLIENT_DICT[clientId] || ''
}