const sidebarReducer = (state = 'En espera', action) => {
  switch (action.type) {
  case 'SIDEBAR':
    return action.name;
  default:
    return state
  }
};

export default sidebarReducer;
