import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css';
import './assets/scss/Index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
// import {ErrorBoundary} from 'react-error-boundary'
import ErrorPage from './Pages/ErrorPage'
import {Provider} from "react-redux"
import store from './Store'
import * as Sentry from "@sentry/react"


if (heap && process.env.NODE_ENV === 'production') { // eslint-disable-line
  // conectar heap solo en produccion
  heap.load(3685680320) // eslint-disable-line

  // eliminar logs en produccion
  console.log = () => {}
  console.debug = () => {}

  Sentry.init({
    dsn: "https://09e8787705944ae3bfccac78f209ef0a@sentry-self.krino.ai/5",
    integrations: [
      new Sentry.BrowserTracing({})
    ],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <Provider store={store}>
//     <ErrorBoundary
//       FallbackComponent={ErrorPage}
//     > 
//       <App />
//     </ErrorBoundary>
//   </Provider>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

