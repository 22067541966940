
const getInitialWelcomeTutorialData = () => {
  const tutorialData = window.localStorage.getItem('krino-welcome-tutorial')
  try {
    if (tutorialData) {
      const parseTutorial = JSON.parse(tutorialData)
      return parseTutorial
    } else {
      const initialData = {
        home: true,
        dashboards: true,
        liveChat: true,
        campains: true,
        templates: true,
        botBuilder: true,
        conversations: true,
        dataTable: true,
        crm: true
      } 
      window.localStorage.setItem('krino-welcome-tutorial', JSON.stringify(initialData))
      return initialData 
    }
  } catch (error) {
    console.log('error parser tutorial reducers')
  }
} 

const initialState = {
  builder: {
    type: null,
    steps: 0
  },
  welcomeTutorial: getInitialWelcomeTutorialData()
}

const tutorialReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'addTutorialType':
    return {...state, [action.section]: {...state[action.section], type: action.tutorialType}}  
  case 'addStepCounter':
    return {...state, [action.section]: {...state[action.section], steps: action.step}} 
  case 'welcomeTutorialToggleSection': {
    return {...state, welcomeTutorial: {...state.welcomeTutorial, [action.section]: !state.welcomeTutorial[action.section]}}
  }
  default:
    return state
  }
}

export default tutorialReducer