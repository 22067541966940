const initialState = {
  chatsOnHoldNotifications: 0
}

const addNotification = (state, action) => {
  const newState = {...state}
  newState[action.notifiactionName] = state[action.notifiactionName] + 1 
  return newState
}

const cleanNotification = (state, action) => {
  const newState = {...state}
  newState[action.notificationName] = 0
  return newState
}

const notificationsReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'addNotification':
    return addNotification(state, action)
  case 'cleanNotification': 
    return cleanNotification(state, action)  
  case 'removeAllNotification': 
    return initialState
  default: 
    return state
  }
}

export default notificationsReducer