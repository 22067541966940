const initialState = {
  source: null,
  sourceHandle: "",
  target: null,
  targetHandle: '',
  id_elemento: null,
  elemento: '',
  validaData: null,
  connectedFaq: 0,
  buttonId: null,
  connectionType: 'standard'
}

const connectionButtonsReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'addConnectionSourceData': 
    return {...state,
      source: action.source,
      sourceHandle:
      action.sourceHandle,
      id_elemento: action.id_elemento,
      elemento: action.elemento,
      validaData: action.validaData,
      connectedFaq: action.connectedFaq,
      buttonId: action.buttonId,
      connectionType: action.connectionType
    }
  case 'addConnectionTargetData':
    return {...state, target: action.target, targetHandle: action.targetHandle}
  case 'removeConnectionButtonsData': 
    return initialState  
  default:
    return state
  }
}

export default connectionButtonsReducer
