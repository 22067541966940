const initialState = {}

const addNewMessage = (state, chatId, newMessage) => {
  const newState = {...state}

  if (!newState[chatId]) {
    newState[chatId] = [newMessage]
  } else { 
    if (Array.isArray(newState[chatId])) {
      newState[chatId].push(newMessage)
    }
  }

  console.log('[unread]', newState)
  return newState
} 

const removeMessages = (state, chatId) => {
  const newState = {...state}
  newState[chatId] = null
  return newState
}

const unreadMessagesReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'addUnreadMessage':
    return addNewMessage(state, action.chatId, action.payload)
  case 'removeUnreadMessages':
    return removeMessages(state, action.chatId)
  default:
    return state
  }
}

export default unreadMessagesReducer