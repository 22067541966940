import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { changeCurretStateAction } from "../../Store/Actions/nuxiba/changeCurrentStateAction";
import { campaignListAction } from "../../Store/Actions/nuxiba/campaignListAction"
import { dialErrorAction } from "../../Store/Actions/nuxiba/dialErrorAction"
import { getDialErrorMessage } from '../../utils/nuxiba/getDialErrorMessage'
import { getMessageFromLog } from "../../utils/nuxiba/getMessageFromLog";
import { logMessage } from "../../Store/Actions/nuxiba/logMessageAction";
import { CallHistoryAction } from "../../Store/Actions/nuxiba/CallHistoryAction";
import { callDispositionsAction } from "../../Store/Actions/nuxiba/callDispositionsAction";
import { lastCallDataAction } from "../../Store/Actions/nuxiba/lastCallDataAction";

export const useNuxibaListeners = (listen = false) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleNuxibaEvent = (event) => {
      const { detail: eventData } = event
      if (eventData.type === 'stateChange') {
        // [onAgentStatus] al actualizar al estado
        const { state } = eventData.data
        dispatch(changeCurretStateAction({ state: state.currentState }))
      } else if (eventData.type === 'campaignList') {
        // [onCampaigns] al recuperar lista de campañas
        const { campaignList } = eventData.data
        const listOfCampaigns = Object.values(campaignList)
        dispatch(campaignListAction({ list: listOfCampaigns }))
      } else if (eventData.type === 'errorDialResult') {
        // [onDialResult] cuando hay un error al marcar a un numero
        const { dialResult } = eventData.data
        const dialMessage = getDialErrorMessage(dialResult)
        dispatch(dialErrorAction({ resultId: dialResult, message: dialMessage }))
      } else if (eventData.type === 'log') {
        // [printToConsole] log generales
        const log = eventData.data
        console.log('[nuxiba log]', log)
        const messageData = getMessageFromLog(log)
        if (messageData) {
          dispatch(logMessage({ message: messageData.message, type: messageData.type }))
        }
      } else if (eventData.type === 'callHistory') {
      // [onCallHistory] historial de llamada
        const history = eventData.data
        dispatch(CallHistoryAction({ history }))
      } else if (eventData.type === 'getDispositions') {
        // [onDispositions] calificacion de llamada
        const dispositions = eventData.data
        dispatch(callDispositionsAction({dispositions}))
      } else if (eventData.type === 'lastCallData') {
        // [onDialingNumber] data de llamada saliente
        const callData = eventData.data
        dispatch(lastCallDataAction({ lastCallData: callData }))
      }
    }

    if (listen) {
      console.log('escuhando')
      document.addEventListener('nuxiba_event', handleNuxibaEvent)
    }

    return () => {
      document.removeEventListener('nuxiba_event', handleNuxibaEvent)
    }
  }, [dispatch]);

}