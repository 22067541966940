import { useEffect } from 'react'

export const useMovileMenu = () => {
  useEffect(() => {
    document.querySelector('*').addEventListener('click', (e) => {
      if (document.body.classList.contains('navigation-open') && e.target.nodeName === 'BODY') {
        document.body.classList.remove('navigation-open')
      }
    })
  }, [])
}