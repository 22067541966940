const initialState = []

const menusReducer = (state = initialState, action) => {

  switch(action.type) {
  case 'updateKriatorMenus': 
    return action.menus
  default:
    return state
  }
}

export default menusReducer