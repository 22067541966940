import React, {useState, useEffect} from 'react'
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Form,
  FormGroup,
  Label,
  ModalHeader,
  Input
} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import classnames from 'classnames'
import { addDashboardToClient } from '../../services/metabase/addDashboardToClient'
import { disableMetabaseLink } from '../../services/metabase/disableMetabaseLink'
import { getMetabaseLinksForAdmin } from '../../services/metabase/getMetabaseLinksForAdmin'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
const MySwal = withReactContent(Swal)

export const KrinoConfigModal = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [addLinkForm, setAddLinkForm] = useState({
    link: '',
    id: ''
  })
  const [disableLinkForm, setDisableLinkForm] = useState({
    id: ''
  })
  const [metabaseLinks, setMetabaseLInks] = useState([])

  useEffect(() => {
    getMetabaseLinksForAdmin()
      .then(data => {
        setMetabaseLInks(data)
      })
  }, [addLinkForm])

  const handleChangeAddLinkForm = (event) => {
    const newData = {...addLinkForm}
    newData[event.target.name] = event.target.value
    setAddLinkForm(newData)
  }

  const handleSubmitAddLinkForm = async (event) => {
    event.preventDefault()
    try {
      await addDashboardToClient(addLinkForm.link, addLinkForm.id)
      MySwal.fire({
        icon: 'success',
        title: 'Link assignado a cliente',
      })
      setAddLinkForm({
        link: '',
        id: ''
      })
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      })
    }
  }

  const handleChangeDisableForm = (event) => {
    const newData = {...disableLinkForm}
    newData[event.target.name] = event.target.value
    setDisableLinkForm(newData)
  }

  const handleSubmitDisableForm = async (event) => {
    event.preventDefault()
    try {
      await disableMetabaseLink(disableLinkForm.id)
      MySwal.fire({
        icon: 'success',
        title: 'Link Desabilitado',
      })
      setDisableLinkForm({id: ''})
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      })
    }
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} centered className="modal-dialog-zoom">
        <ModalHeader toggle={props.toggle}>
          <FeatherIcon.Edit2 className="mr-2"/> Krino
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '1'})}
                onClick={() => {
                  toggle('1');
                }}
              >
                Metabase
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '2'})}
                onClick={() => {
                  toggle('2');
                }}
              >
                otro
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div>
                <h3>Añadir link para cliente</h3>
                <Form onSubmit={handleSubmitAddLinkForm}>
                  <FormGroup>
                    <Label
                      for="MetabaseLink"
                    >
                      Link Metabase
                    </Label>
                    <Input
                      required
                      onChange={handleChangeAddLinkForm}
                      name='link'
                      type="text"
                      id="MetabaseLink"
                      placeholder="http://ec2-52-87-221-38.compute-1.amazonaws.com/public/dashboard/..."
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="MetabaseId"
                    >
                      Id de cliente
                    </Label>
                    <Input
                      required
                      onChange={handleChangeAddLinkForm}
                      name='id'
                      type="number"
                      id="MetabaseId"
                    />
                  </FormGroup>
                  <Button className='krino__button'>Añadir</Button>
                </Form>
              </div>
              <hr />
              <div>
                <h3>Desactivar link de cliente</h3>
                <Form onSubmit={handleSubmitDisableForm}>
                  <FormGroup>
                    <Label
                      for="disableLink"
                    >
                      Id de Link
                    </Label>
                    <Input
                      type="select"
                      name='id'
                      value={disableLinkForm.id}
                      onChange={handleChangeDisableForm}
                    >
                      <option value="" hidden>selecciona el id</option>
                      {
                        metabaseLinks.length
                          ? (
                            metabaseLinks.map(link => {
                              if (link.status === false) {
                                return null
                              }
                              return (
                                <option
                                  key={`metabaseLink-${link.id}`}
                                  value={link.id}
                                >
                                  {link.link}
                                </option>
                              )
                            })
                          )
                          : (
                            null
                          )
                      }                      
                    </Input>
                    {/* <Input
                      required
                      onChange={handleChangeDisableForm}
                      name='id'
                      type="number"
                      id="disableLink"
                    /> */}
                  </FormGroup>
                  <Button className='krino__button'>Desactivar</Button>
                </Form>
              </div>
            </TabPane>
            <TabPane tabId="2">
                2
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  )
}
