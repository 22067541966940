import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('da3b26de0e4dca73ec1d7a78d2acd0260eab4328286d15eb981b74b4e69fa95e')
// const baseUrl = 'https://improvebot.krino.ai/da3b26de0e4dca73ec1d7a78d2acd0260eab4328286d15eb981b74b4e69fa95e'

export const getMetabaseLinksForAdmin = async () => {
  const { data } = await axios.get(baseUrl, {
    headers: {
      token: getToken()
    }
  })

  return data
}