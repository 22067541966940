
const loadFormLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem('krino-liveChat-tags')) || []
}

const saveInLocalStorage = (data) => {
  window.localStorage.setItem('krino-liveChat-tags', JSON.stringify(data))
}

const initialState = {
  tags: loadFormLocalStorage(),
}

const liveChatTagsReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'liveChatTags/update':
    saveInLocalStorage(action.tags)
    return {...state, tags: action.tags}
  default:
    return state
  }
}

export default liveChatTagsReducer