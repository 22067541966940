const initialState = {
  elements: [],
  elementsDict: {}
}

const generateDict = (elements, begginIndex = 0) => {
  const dict = elements.reduce((prev, current, index) => {
    if (prev === null) {
      return {
        [current.id]: begginIndex + index
      }
    }
    const final = prev
    final[current.id] = begginIndex + index
    return final
  }, null)

  return dict
}
 
const handleDeleteElement = (elements, deleteIndex) => {
  const filteredElements = [...elements].splice(deleteIndex, 1)
  return {
    elements: filteredElements,
    elementsDict: generateDict(filteredElements)
  } 
}

const handleUpdateElement = (elements, newElement, newElementIndex) => {
  const updatedElements = [...elements]
  updatedElements[newElementIndex] = newElement
  return updatedElements
}

const botBuilderReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'botBuilder/addElement':
    return {
      elements: [...state.elements, action.element],
      elementsDict: {...state.elementsDict, [action.element.id]: state.elements.length}
    } 
  case 'botBuilder/addElements':
    return {
      elements: [...state.elements, ...action.elements],
      elementsDict: {...state.elementsDict, ...generateDict(action.elements, state.elements.length)}
    } 
  case 'botBuilder/deleteElement':
    return handleDeleteElement(state.elements, state.elementsDict[action.id])  
  case 'botBuilder/updateElement':
    return {
      ...state,
      elements: handleUpdateElement(state.elements, action.element, state.elementsDict[action.element.id])
    } 
  case 'botBuilder/removeAllElements':
    return initialState
  case 'botBuilder/replaceAllElements':
    return {
      elements: action.elements,
      elementsDict: generateDict(action.elements)
    } 
  default: 
    return state
  }
}

export default botBuilderReducer