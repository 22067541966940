const initialState = {
  // loadHistoryLiveChat: false
}
// por el momento no es usado pero puede ser util para manejar animaciones
const AnimationsReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'animations': 
    return {...state, animation: action.payload}
  default: 
    return state
  }
}

export default AnimationsReducer