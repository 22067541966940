const initialState = []

const archiveChatReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'addChatToArchive': 
    return [...state, action.chat]
  default: 
    return state
  }
}

export default archiveChatReducer