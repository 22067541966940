const initialState = {
  currentState: null,
  areFilesLoaded: false,
  campaignList: [],
  dialError: {
    resultId: null,
    message: ''
  },
  logMessage: {
    message: '',
    type: null
  },
  callHistory: [],
  lastCallData: {},
  callDispositions: []
}

const nuxibaReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'nuxiba_currentState':
    return { ...state, currentState: action.currentState }
  case 'nuxiba_loadFiles':
    return { ...state, areFilesLoaded: true }
  case 'nuxiba_campaignList':
    return { ...state, campaignList: action.list }
  case 'nuxiba_dialError':
    return { ...state, dialError: { resultId: action.resultId, message: action.message } }
  case 'nuxiba_logMessage':
    return { ...state, logMessage: { type: action.messageType, message: action.message } }
  case 'nuxiba_callHistory':
    return { ...state, callHistory: action.history }
  case 'nuxiba_lastCallData':
    return { ...state, lastCallData: action.lastCallData }
  case 'nuxiba_callDispositions':
    return { ...state, callDispositions: action.dispositions }
  default:
    return state
  }
}

export default nuxibaReducer