import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getKrinoClientValidation } from './services/getKrinoClientValidation'
import { userIsKrinoAction } from './Store/Actions/userIsKrinoAction'
import { getProfileData } from './services/onboarding/getProfileData'
import { PageLayout } from './App/Components/PageLayout'
import { getTokenInfo } from './utils/getTokenInfo'
import { userDataAction } from './Store/Actions/userDataAction'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { connectNotificationSocket } from './services/notifications/connetNotificationSocket'
import { useLoadNuxibaFiles } from './hooks/gocar/useLoadNuxibaFiles.js'
import { useNuxibaListeners } from './hooks/nuxiba/useNuxibaListeners.js'
import { CLIENT_NAMES } from './utils/clients/clientsNames.js'
import { getClientNameById } from './utils/clients/clients_dict.js'
import { clientHasPermision, clientPermissions } from './utils/clients/clientPermissions.js'

const LoginOld = lazy(() => import('./Pages/LoginOld'))
const Login = lazy(() => import('./Pages/Login'))
const SignUp = lazy(() => import('./Pages/SignUp'))
const ResetPassword = lazy(() => import('./Pages/ResetPassword'))
const Layout = lazy(() => import('./App/Layout'))
const BotBuilder = lazy(() => import('./Pages/BotBuilder'))
const Dashboard = lazy(() => import('./Pages/Dashboard'))
const BotBuilderSelection = lazy(() => import('./Pages/BotBuilderSelection'))
const BotSectionSelector = lazy(() => import('./Pages/BotSectionSelector'))
const Campaign = lazy(() => import('./Pages/Campaign.js'))
const NewBot = lazy(() => import('./Pages/NewBot'))
const DataTable = lazy(() => import('./Pages/DataTable'))
const DataTableSelection = lazy(() => import('./Pages/DataTableSelection'))
const CampaignInfo = lazy(() => import('./Pages/CampaignInfo'))
const LaunchBot = lazy(() => import('./Pages/LaunchBot'))
const Templates = lazy(() => import('./Pages/Templates'))
const Conversations = lazy(() => import('./Pages/Conversations'))
const ConversationTable = lazy(() => import('./Pages/ConversationTable'))
const TestYourBot = lazy(() => import('./Pages/TestYourBot'))
const Payment = lazy(() => import('./Pages/Payment'))
const PaymentStatus = lazy(() => import('./Pages/PaymentStatus'))
const Billing = lazy(() => import('./Pages/Billing'))
const TermsAndConditions = lazy(() => import('./Pages/TermsAndConditions'))
const SetPassword = lazy(() => import('./Pages/SetPassword'))
const VerifyAccount = lazy(() => import('./Pages/VerifyAccount'))
const Crm = lazy(() => import('./Pages/Crm'))
const Home = lazy(() => import('./Pages/Home'))
const FaqSectionSelection = lazy(() => import('./Pages/FaqSectionSelection'))
const Profile = lazy (() => import('./Pages/Profile'))
const AdminMenu = lazy (() => import('./Pages/AdminMenu'))
const LiveChatAdmin = lazy(() => import('./Pages/LiveChatAdmin')) 
const NumberActivate = lazy(() => import('./Pages/NumberActivate'))
const PoweredByKrino = lazy(() => import('./Pages/PoweredByKrino'))
const TemplateContructor = lazy(() => import('./Pages/TemplateContructor'))
const CampaingDashboard = lazy(() => import('./Pages/CampaingDashboard'))
const TemplateConfig = lazy(() => import('./Pages/TemplateConfig'))
const NotFount = lazy(() => import('./Pages/NotFount'))
const Onboarding = lazy(() => import('./Pages/Onboarding'))
const Tutorials = lazy(() => import('./Pages/Tutorials'))
const FacebookAuth = lazy(() => import('./Pages/FacebookAuth'))
// const BotData = lazy(() => import('./Pages/BotData'))
const BotBuilderFlowData = lazy(() => import('./Pages/BotBuilderFlowData'))
const BotSettings = lazy(() => import('./Pages/BotSettings'))
const RedirectToPage = lazy(() => import('./Pages/RedirectToPage'))

const LeadControlPanel = lazy(() => import('./Pages/leadControlPanel'))
const TemperatureLeadModel = lazy(() => import('./Pages/TemperatureLeadModels'))
const TemperatureLeadModelConfig = lazy(() => import('./Pages/TemperatureLeadModelConfig'))
const EditPermissions = lazy(() => import('./Pages/editPermission'));
const ProfileConfig = lazy(() => import('./Pages/ProfileConfig'));
const LoadingFallback = (
  <div className='position--center pageLoadingSpinner'>
    <Spinner style={{ width: '5rem', height: '5rem' }} color='primary'/>
  </div>
)

function App() {
  const user = useSelector(store => store.user)
  // const { botId } = useSelector(state => state.botBuilderData)
  // const isLogged = user.token ? true : false
  const [isLogged, setIsLogged] = useState(user.token ? true : false)
  const [clientName, setClientName] = useState('')
  const [isAdmin, setIsAdmin] = useState(false); 

  useLoadNuxibaFiles({ load: clientName === CLIENT_NAMES.GOCAR })
  useNuxibaListeners({ listen: clientName === CLIENT_NAMES.GOCAR })
  const dispatch = useDispatch()

  // verifica si el usuarios esta loggeado
  useEffect(() => {
    const tokenStatus = user.token ? true : false
    if (tokenStatus !== isLogged) {
      setIsLogged(tokenStatus)
    }
  }, [user, isLogged, dispatch])

  // busca datos del usuario
  useEffect(() => {
    if (isLogged) {
      // validacion de perfil de cliente
      getKrinoClientValidation()
        .then(({valid, permiso}) => {
          dispatch(userIsKrinoAction(valid))
          setIsAdmin(permiso === 1 || permiso === 2)
          console.log('permiso', permiso)
        })

      // se conecta con el socket de notificaciones
      connectNotificationSocket()

      // datos del perfil del cliente
      getProfileData()
        .then(profileData => {
          dispatch(userDataAction(profileData))
          if (profileData.first_time === 's' && window.location.pathname !== '/onboarding/'){  
            window.location.replace('/onboarding/')
          }
        })
    }

  }, [isLogged, dispatch])

  useEffect(() => {
    if (isLogged) {
      const tokenData = getTokenInfo()
      const client =  getClientNameById(tokenData.id_cliente)
      setClientName(client)
    }
  }, [isLogged])

  let aplication = null

  if (isLogged) { // rutas con login
    aplication = (
      <Router>
        <PageLayout>
          <Suspense fallback={LoadingFallback}>
            <Switch>
              <Route
                exact={true}
                path="/login"
                render={() => {
                  const params = new URLSearchParams(document.location.search)
                  const redirect = params.get('redirect')
                  if (redirect) {
                    return <Redirect to={redirect} />
                  }
                  return <Redirect to='/' />
                }}
              />
              <Route
                exact={true}
                path="/login/old"
                render={() => <Redirect to='/' />}
              />
              <Route
                path="/sign-up"
                render={() => <Redirect to='/' />}
              />
              <Route
                path="/reset-password"
                render={() => <Redirect to='/'/>}
              />
              <Route
                exact={true}
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route
                exact={true}
                path="/set-password/:token"
                render={() => <Redirect to='/' />}
              />
              <Route
                exact={true}
                path="/activate-account/:token"
                render={() => <Redirect to='/' />}
              />
              <Route
                path={'/byKrino/:number/:message'}
                exact={true}
                render={() => <Redirect to='/' />}
              />
              <Route
                path={'/byKrino/:number'}
                exact={true}
                render={() => <Redirect to='/' />}
              />
              <Route
                path={'/chat'}
                exact={true}
                // component={Layout}
                render={() => <Layout isEcrClient={clientName === CLIENT_NAMES.ECR}/>}
              />
              <Route
                path={'/chat/admin'}
                exact={true}
                component={LiveChatAdmin}
                // render={() => isLogged ? <LiveChatAdmin /> : <Redirect to='/login' />}
              />
              <Route
                path={'/chat/admin/:section'}
                exact={true}
                component={LiveChatAdmin}
                // render={() => isLogged ? <LiveChatAdmin /> : <Redirect to='/login' />}
              />
              <Route
                path="/bot-builder"
                exact={true}
                component={BotBuilderSelection}
                // render={() => {
                //   if (!isLogged) {
                //     return <Redirect to='/login' />
                //   }
                //   return <BotBuilderSelection />
                // }}
              />
            
              <Route
                path="/bot-builder/new"
                exact={true}
                component={NewBot}
                // render={() => {
                //   if (!isLogged) {
                //     return <Redirect to='/login' />
                //   }
                //   return <NewBot />
                // }}
              />
              <Route
                path="/bot-builder/:botId"
                exact={true}
                component={BotBuilder}
                // render={() => {
                //   if (!isLogged) {
                //     return <Redirect to='/login' />
                //   }
                //   return <BotBuilder />
                // }}
              />
              <Route
                path="/bot/sections/:botId"
                exact={true}
                component={BotSectionSelector}
              />
              <Route
                path="/bot-builder/section/:sectionId"
                exact={true}
                render={() => <BotBuilder section={true}/>}
                // render={() => {
                //   if (!isLogged) {
                //     return <Redirect to='/login' />
                //   }
                //   return <BotBuilder section={true}/>
                // }}
              />
              <Route
                path={'/profile-config'}
                exact={true}
                component={ProfileConfig}
              />
              <Route path="/edit-permissions/:profileId" exact component={EditPermissions} />
              <Route
                path="/dashboard"
                exact={true}
                // component={Dashboard}
                render={() => <Dashboard isEcrClient={clientName === CLIENT_NAMES.ECR} /> }
              />
              <Route   
                path="/dataTable"
                exact={true}
                render={() => <DataTableSelection link={'dataTable'}/>}
                // render={() => isLogged ? <DataTableSelection link={'dataTable'}/> : <Redirect to='/login' />}
              />
              <Route   
                path="/dataTable/:botId"
                exact={true}
                component={DataTable}
                // render={() => isLogged ? <DataTable /> : <Redirect to='/login' />}
              />
              <Route
                path={['/campaign', '/campaign/:action']}
                exact={true}
                component={Campaign}
                // render={() => isLogged ? <Campaign /> : <Redirect to='/login' />}
              />
              <Route
                path={['/campaign/new/template']}
                exact={true}
                component={TemplateContructor}
                // render={() => isLogged ? <TemplateContructor /> : <Redirect to='/login' />}
              />
              <Route
                path='/campaign/metrics/:campaignId'
                exact={true}
                component={CampaingDashboard}
                // render={() => isLogged ? <CampaingDashboard /> : <Redirect to='/login' />}
              />
              <Route
                path='/campaign/template/:templateId'
                exact={true}
                component={TemplateConfig}
                // render={() => isLogged ? <CampaingDashboard /> : <Redirect to='/login' />}
              />
              <Route
                path={'/campaign/info/:campaignId/:botId'}
                exact={true}
                component={CampaignInfo}
                // render={() => isLogged ? <CampaignInfo /> : <Redirect to='/login' />}
              />
              <Route
                path={'/bot/launch/:id'}
                exact={true}
                component={LaunchBot}
                // render={() => isLogged ? <LaunchBot /> : <Redirect to='/login' />}
              />
              <Route
                path={'/bot/test/:id'}
                exact={true}
                component={TestYourBot}
                // render={() => isLogged ? <TestYourBot /> : <Redirect to='/login' />}
              />
              <Route
                path={'/templates/'}
                exact={true}
                component={Templates}
                // render={() => isLogged ? <Templates /> : <Redirect to='/login' />}
              />
              <Route
                path={'/conversations/record/:chatId'}
                exact={true}
                component={Conversations}
                // render={() => isLogged ? <Conversations /> : <Redirect to='/login' />}
              />
              <Route
                path={'/conversations/external/record/:token'}
                exact={true}
                render={() => <Conversations externalUser={true}/>}
              />
              <Route   
                path="/conversations/"
                exact={true}
                render={() => <DataTableSelection link={'conversations'}/>}
                // render={() => isLogged ? <DataTableSelection link={'conversations'}/> : <Redirect to='/login' />}
              />
              <Route
                path={'/conversations/:botId'}
                exact={true}
                component={ConversationTable}
                // render={() => isLogged ? <ConversationTable /> : <Redirect to='/login' />}
              />
              <Route
                path={'/payment/'}
                exact={true}
                component={Payment}
                // render={() => isLogged ? <Payment /> : <Redirect to='/login' />}
              />
              <Route
                path={'/payment/:status'}
                exact={true}
                component={PaymentStatus}
                // render={() => isLogged ? <PaymentStatus /> : <Redirect to='/login' />}
              />
              <Route
                path={'/billing/'}
                exact={true}
                component={Billing}
                // render={() => isLogged ? <Billing /> : <Redirect to='/login' />}
              />
              <Route
                path={'/Crm/'}
                exact={true}
                render={() => <Crm makeCallPermision={clientHasPermision(clientName, clientPermissions.makeNuxibaCall)}  isAdmin={isAdmin} /> }
              />
              <Route
                path={'/Crm/:tokenLead'}
                exact={true}
                render={() => <Crm makeCallPermision={clientHasPermision(clientName, clientPermissions.makeNuxibaCall)}  isAdmin={isAdmin} /> }
              />
              <Route
                path='/'
                exact={true}
                component={Home}
                // render={() => isLogged ? <Home /> : <Redirect to='/login' />}
              />
              <Route
                path={'/bot-sections/'}
                exact={true}
                component={FaqSectionSelection}
                // render={() => isLogged ? <FaqSectionSelection /> : <Redirect to='/login' />}
              />
              <Route
                path={'/profile/'}
                exact={true}
                component={Profile}
                // render={() => isLogged ? <Profile /> : <Redirect to='/login' />}
              />
              <Route
                path={'/profile/:section'}
                exact={true}
                component={Profile}
                // render={() => isLogged ? <Profile /> : <Redirect to='/login' />}
              />
              <Route
                path={'/AdminMenu/'}
                exact={true}
                component={AdminMenu}
                // render={() => isLogged ? <AdminMenu /> : <Redirect to='/login' />}
              />
              <Route
                path={'/number/activate/:number'}
                exact={true}
                component={NumberActivate}
                // render={() => isLogged ? <NumberActivate /> : <Redirect to='/login' />}
              />
              <Route
                path={'/onboarding'}
                exact={true}
                component={Onboarding}
                // render={() => isLogged ? <Onboarding /> : <Redirect to='/login' />}
              />
              <Route
                path={'/tutorials'}
                exact={true}
                // render={() => isLogged ? <Tutorials /> : <Redirect to='/login' />}
                component={Tutorials}
              />
              <Route
                path={'/byKrino/:number/:message'}
                exact={true}
                component={PoweredByKrino}
              />
              <Route
                path={'/byKrino/:number/:message'}
                exact={true}
                component={PoweredByKrino}
              />
              <Route
                path={'/bot/data/:botId'}
                exact={true}
                component={BotBuilderFlowData}
              />
              <Route
                path={'/meta/auth/'}
                exact={true}
                component={FacebookAuth}
              />
              <Route
                path={'/bot/settings/:botId'}
                exact={true}
                component={BotSettings}
              />
              <Route
                path={'/redirect'}
                exact={true}
                component={RedirectToPage}
              />
              <Route
                path={'/lead-control-panel'}
                exact={true}
                component={LeadControlPanel}
              />
              <Route
                path={'/lead-temperature-models'}
                exact={true}
                component={TemperatureLeadModel}
              />
              <Route
                path={'/model-config/:modelId'}
                exact={true}
                component={TemperatureLeadModelConfig}
              />
              <Route path="*" >
                <NotFount isLogged={isLogged}/>
              </Route>
            </Switch>
          </Suspense>
        </PageLayout>
      </Router>
    )
  } else { // rutas sin login
    aplication = (
      <Router>
        <Suspense fallback={LoadingFallback}>
          <Switch>
            <Route
              exact={true}
              path="/login"
              render={() => isLogged ? <Redirect to='/' /> : <Login />}
            />
            <Route
              exact={true}
              path="/login/old"
              render={() => isLogged ? <Redirect to='/' /> : <LoginOld />}
            />
            <Route path="/sign-up" component={SignUp}/>
            <Route path="/reset-password" component={ResetPassword}/>
            <Route path="/terms-and-conditions" component={TermsAndConditions}/>
            <Route exact={true} path="/set-password/:token" component={SetPassword}/>
            <Route exact={true} path="/activate-account/:token" component={VerifyAccount}/>
            <Route path={'/byKrino/:number/:message'} exact={true} component={PoweredByKrino} />
            <Route path={'/byKrino/:number'} exact={true} component={PoweredByKrino} />
            <Route
              path={'/conversations/external/record/:token'}
              exact={true}
              render={() => <Conversations externalUser={true}/>}
            />
            <Route
              path={'/redirect'}
              exact={true}
              component={RedirectToPage}
            />
            <Route path="*" >
              <Redirect
                to={document.location.pathname === '/'
                  ? '/login'
                  : `/login?redirect=${document.location.pathname}`}
              />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    ) 
  }

  try {
    return aplication
  } catch (error) {
    console.log('entrando en error')
    return <h1>error</h1>
  }
}

export default App
