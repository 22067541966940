const initialState = []

const currentMessagesReducer = (state = initialState, action) => {

  switch(action.type) {
  case 'addMessageCurrent': 
    return [...state, action.message]
  case 'updateMessageCurrent': 
    return state.map(message => message.id === action.message.id ? action.message : message)
  case 'loadCurrentChathistory':
    return  action.history
  case 'setCurrentMessageAsDelete':
    if (action.id === 'None') return state
    return state.map(message => {
      if (message.id === action.id || message.mId === action.id) {
        message.is_deleted = true
      }
      return message
    }) 
  case 'cleanCurrentChatHistory':
    return []
  default:
    return state
  }
}

export default currentMessagesReducer