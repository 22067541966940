
const posibleMessages = [
  {
    type: 'error',
    matchText: 'remoteLoginError: 1922',
    message: 'Usuario o contraseña incorrectos',
  },
  {
    type: 'error',
    matchText: 'Wrong Number:',
    message: 'Número de teléfono inválido.',
  },
  {
    type: 'success',
    matchText: 'Disposition Applied',
    message: 'Calificación guardada exitosamente.',
  }
]

export const getMessageFromLog = (log) => {
  return posibleMessages.find(message => log.includes(message.matchText)) ?? null
}