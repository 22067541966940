import jwt_decode from "jwt-decode"
import { getToken } from "../services/getToken"

// retorna la informacion del token, por defecto utiliza el token en storage
export const getTokenInfo = (token = null) => {
  try {
    const tokenForDecode = token || getToken()
    const tokenData = jwt_decode(tokenForDecode)
    return tokenData
  } catch (error) {
    console.error('[getTokenInfo] algo fallo')
    return {}
  }
}