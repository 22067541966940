import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('d65d81cff2d5b3fce4a7a9dfe218c156ff19f1dfe1a58021a31c8ac6eecc4cda')
// const baseUrl = 'https://improvebot.krino.ai/d65d81cff2d5b3fce4a7a9dfe218c156ff19f1dfe1a58021a31c8ac6eecc4cda'

export const isSuscriptionActive = async () => {
  const { data } = await axios.get(baseUrl, {
    headers: {
      token: getToken()
    }
  }) 

  return data
}