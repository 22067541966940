import { useEffect } from 'react'
import { socket } from '../../../services/socket'
import { notification } from '../../../utils/notification'

export const NotificationWrapper = ({ children }) => {
  
  useEffect(() => {
    const handleNotification = (notificationData) => {
      console.log('notification data', notificationData)
      if (notificationData.type === 'temperature_lead_new_leads') {
        // este tipo es usado para recargar los leads en el LCP
        return
      }
      notification(notificationData.message)
    }

    socket.on('notification', handleNotification)
  
    return () => {
      socket.off('notification', handleNotification)
    }
  }, [])
  
  return children
}