import { CLIENT_NAMES } from "./clientsNames";
// diccionario de permisos
export const clientPermissions = {
  makeNuxibaCall: "makeNuxibaCall"
}

// diccionario de nombres de clientes relacionado a la lista de permisos que tienen
export const permissionsByClient = {
  [CLIENT_NAMES.GOCAR]: [clientPermissions.makeNuxibaCall]
}

export const getPermisionsByClientName = (clientName) => {
  return permissionsByClient[clientName] ?? null
}

export const clientHasPermision = (clientName, permissionName) => {
  const clientPermissions = permissionsByClient[clientName]
  if (!clientPermissions) return false
  return clientPermissions.includes(permissionName)
}