import {createStore, combineReducers} from "redux"

import sidebarReducer from "./Reducers/sidebarReducer"
import mobileSidebarReducer from "./Reducers/mobileSidebarReducer"
import profileSidebarReducer from "./Reducers/profileSidebarReducer"
import mobileProfileSidebarReducer from "./Reducers/mobileProfileSidebarReducer"
import selectedChatReducer from "./Reducers/selectedChatReducer"
import assignedChastsReducer from './Reducers/assignedChastsReducer'
import chatsOnHoldReducer from "./Reducers/chatsOnHoldReducer"
import userReducer from './Reducers/userReducer'
import themeReducer from './Reducers/themeReducer'
import unreadMessagesReducer from "./Reducers/unreadMessagesReducer"
import currentMessagesReducer from "./Reducers/currentMessagesReducer"
import archiveChatReducer from "./Reducers/archivedChatsReducer"
import botBuilderReducer from "./Reducers/botBuilderReducer"
import botBuilderDataReducer from "./Reducers/botBuilderDataReducer"
import notificationsReducer from "./Reducers/notificationsReducer"
import connectionButtonsReducer from './Reducers/connectionButtonsReducer'
import tutorialReducer from "./Reducers/tutorialReducer"
import botBuilderNotes from './Reducers/botBuilderNotes'
import onboardingReducer from "./Reducers/onboardingReducer"
import menusReducer from "./Reducers/menusReducer"
import liveChatTagsReducer from "./Reducers/liveChatTagsReducer"
import AnimationsReducer from "./Reducers/AnimationsReducer"
import nuxibaReducer from "./Reducers/nuxibaReducer"

const reducers = combineReducers({
  selectedSidebar: sidebarReducer,
  mobileSidebar: mobileSidebarReducer,
  profileSidebar: profileSidebarReducer,
  mobileProfileSidebar: mobileProfileSidebarReducer,
  selectedChat: selectedChatReducer,
  assignedChats: assignedChastsReducer,
  chatsOnHold: chatsOnHoldReducer,
  unreadMessages: unreadMessagesReducer,
  currentMessages: currentMessagesReducer,
  archived: archiveChatReducer,
  user: userReducer,
  theme: themeReducer,
  botBuilder: botBuilderReducer,
  botBuilderData: botBuilderDataReducer,
  notifications: notificationsReducer,
  connectionButtons: connectionButtonsReducer,
  tutorial: tutorialReducer,
  notes: botBuilderNotes,
  onboarding: onboardingReducer,
  menus: menusReducer,
  liveChatTags: liveChatTagsReducer,
  animations: AnimationsReducer,
  nuxiba: nuxibaReducer
});

const store = createStore(reducers);

export default store
