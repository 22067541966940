import React, { useState, Fragment } from 'react'
import Joyride from 'react-joyride'
import { toggleWelcomeTutorialAction } from '../../Store/Actions/tutorial/toggleWelcomeTutorialAction'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
// {
//   target: '.my-first-step',
//   content: 'This is my awesome feature!',
// },

const styles = {
  options: {
    primaryColor: '#1597E5',
    zIndex: 2000,
  }
  
}

export const Tour = ({
  steps,
  run = false,
  initialTutorial = false,
  tutorialName = '',
  callback = () => {},
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [runTutorial, setRunTutorial] = useState(run) 

  const handleBeginTutorial = () => {
    dispatch(toggleWelcomeTutorialAction(tutorialName))
    setRunTutorial(true)
  } 

  const handleCallback = (callbackData) => {
    if (callbackData.step.content.includes('[redirect]')) {
      const url = callbackData.step.content.slice(11)
      history.push(url)
    }

    callback(callbackData)
  }

  return (
    <Fragment>
      {
        (initialTutorial && !runTutorial) && (
          <div className='tourButton_container'>
            <div>
              <button
                className='krino__button'
                onClick={handleBeginTutorial}
              >
                Ver tutorial
              </button>
            </div>
          </div>
        )
      }

      <Joyride
        run={runTutorial}
        continuous={true}
        steps={steps}
        styles={styles}
        callback={handleCallback}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 2000
            },
          },
        }}
      />
    </Fragment>
  )
}