const notificationImg = 'https://static-for-flask-krino.s3.amazonaws.com/improve_bot/static/img/bot/jztz4OCJCkkhNZ.png' 

export const notification = (message, PlayAudio = true) => {

  const options = {
    body: message,
    icon: notificationImg,
    silent: !PlayAudio
  } 

  if (!("Notification" in window)) {
    console.log("Este navegador no soporta notificaciones")

  } else if (Notification.permission === "granted") {
    new Notification('Krino', options)

  } else if (Notification.permission !== 'denied') {
    console.log('pidiendo permiso')
    Notification.requestPermission(function (permission) {

      if (permission === "granted") {
        new Notification('Krino', options)
      }
    });
  }
}