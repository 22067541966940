import { isChatUnique } from "../../utils/isChatUnique"
const initialState = []

const assignedChastsReducer = (state = initialState, action) => {

  switch(action.type) {
  case 'assing_chat':
    return isChatUnique(state, action.chat) ? [...state, action.chat] : state
  case 'remove_assigned_chat':
    return state.filter(item => item.ticket !== action.chat.ticket)
  default: 
    return state
  }
}

export default assignedChastsReducer