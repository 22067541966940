import React, { useEffect } from 'react'
import Navigation from '../Navigation'
import { MovileMenu } from './MobileMenu'
import { useMovileMenu } from '../../hooks/useMovileMenu'
import { useHistory } from 'react-router-dom'
import { useReducer } from 'react'
import { NotificationWrapper } from './notification/NotificationWrapper'

// en estas rutas no se mostrar al layout
const ignoreRouters = [
  // '/payment/'
]

export const PageLayout = ({ children }) => {
  useMovileMenu()
  const history = useHistory()
  const [, forceRerender] = useReducer((x) => x + 1, 0)
  const ignoreRoute = ignoreRouters.includes(window.location.pathname)

  useEffect(() => {
    return history.listen(() => {
      // console.log(`You changed the page to: ${location.pathname}`)
      forceRerender()
    })
  }, [history])
  

  if (ignoreRoute) {
    return (
      children
    ) 
  }

  return (
    <NotificationWrapper>
      <div className="layout">
        <div className="content">
          <Navigation/>
          <MovileMenu />
          {children}
        </div>
      </div>
    </NotificationWrapper>
  )
}