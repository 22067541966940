import axios from 'axios'
import { getToken } from '../getToken' 
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('031358269e13e325052bc67da980765c48e962a539b167040a1cced16ad6c4db')
// const baseUrl = 'https://improvebot.krino.ai/031358269e13e325052bc67da980765c48e962a539b167040a1cced16ad6c4db'

export const cancelSuscription = async (suscription) => {
  const url = `${baseUrl}/${suscription}`
  
  const { data } = await axios(url, {
    headers: {
      token: getToken()
    }
  })

  return data
}