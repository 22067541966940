export const toggleWelcomeTutorialAction = (section) => {
 
  const storageTutorial = JSON.parse(window.localStorage.getItem('krino-welcome-tutorial'))
  storageTutorial[section] = !storageTutorial[section] 
  window.localStorage.setItem('krino-welcome-tutorial', JSON.stringify(storageTutorial))
  
  return {
    section,
    type: 'welcomeTutorialToggleSection'
  }
}