import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { loadFilesAction } from "../../Store/Actions/nuxiba/loadFilesAction"

const LoadStaticFile = (file) => {
  const script = document.createElement('script')
  script.src = file
  script.async = true
  document.body.appendChild(script)
}
export const useLoadNuxibaFiles = ({ load = false }) => {
  const dispach = useDispatch()

  useEffect(() => {
    if (load) {
      LoadStaticFile('/Method.js')
      LoadStaticFile('/cw-galatea-integration-api-js-bundle.min.js')
      LoadStaticFile('/CORE_APIEvents.js')
      const intervalId = setInterval(() => {
        if (window.connectToServer) {
          dispach(loadFilesAction())
          clearInterval(intervalId)
        }
      }, 1000)
    }
  }, [load, dispach])
}