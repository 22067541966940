const initialState = {
  profile: {
    name: '',
    lastName: '',
    phone: ''
  },
  business: {
    name: '',
    optimization: '',
    other: '',
    teamSize: ''
  },
  avatar: {
    type: 'male',
    item: null
  },
  liveChat: {
    email: ''
  },
  invite: {
    emailList: []
  }
}

const onboardingReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'onboarding/update/profile':
    return { ...state, profile: action.payload }
  case 'onboarding/update/business':
    return { ...state, business: action.payload }
  case 'onboarding/update/avatar':
    return { ...state, avatar: action.payload }
  case 'onboarding/update/liveChat':
    return { ...state, liveChat: action.payload }
  case 'onboarding/update/invite':
    return { ...state, invite: action.payload }
  default: 
    return state
  }
}

export default onboardingReducer