
// TODO: mover validacion a builder
const getSectionOnUrl = () => {
  if (document.location.pathname.includes('bot-builder')) {
    const urlParams = document.location.search
    const searchParams = new URLSearchParams(urlParams)
    const section = searchParams.get('section')
    if (section === 'null') return null
    return section
  }
  return null
}

const setSectionOnUrl = (sectionName) => {
  window.history.replaceState(null, null, `?section=${sectionName}`);
}

const initialState = {
  botId: window.localStorage.getItem('botBuilder_botId') || null,
  blockedFaq: null,
  enableButtonLogic: false,
  isGupshup: false, 
  zoom: true,
  botApis: [],
  sectionList: [],
  selectedSelection: getSectionOnUrl(),
  showAddSectionModal: false,
  moveToPosition: {
    x: null, y: null
  }
}

const botBuilderDataReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'update/botBuilderData/id': 
    return {...state, botId: action.id}
  case 'update/botBuilderData/blockedFaq': 
    return {...state, blockedFaq: action.blocked}
  case 'update/botBuilderData/buttonLogic':
    return {...state, enableButtonLogic: action.buttonLogic }
  case 'update/botBuilderData/isGupsgup':
    return {...state, isGupshup: action.isGupshup }
  case 'update/botBuilderData/zoom':
    return {...state, zoom: action.zoom }
  case 'update/botBuilderData/botApis':
    return {...state, botApis: action.payload }
  case 'update/botBuilderData/sectionList':
    return {...state, sectionList: action.payload }
  case 'update/botBuilderData/selectedSelection':
    setSectionOnUrl(action.payload)
    return {...state, selectedSelection: action.payload }
  case 'update/botBuilderData/moveToPosition':
    return {...state, moveToPosition: action.payload }
  case 'update/botBuilderData/showAddSectionModal':
    return {...state, showAddSectionModal: action.payload }
  default:
    return state
  }
}

export default botBuilderDataReducer