import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export const toast = (config, type = 'info') => {

  const defaultConfig = {
    text: "",
    duration: 3000,
    destination: "",
    newWindow: true,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      backgroundColor: '#204191',
      background: "linear-gradient(to left, #204191, #1597E5);",
    },
    onClick: function(){} // Callback after click
  }
  
  const finalConfig = Object.assign(defaultConfig, config)    

  switch(type) {
  case 'info':
    finalConfig.style = {
      background: "linear-gradient(to left, #204191, #1597E5)",
    }
    break
  case 'success':
    finalConfig.style = {
      background: "linear-gradient(to left, #229120, #13d110)",
    }
    break
  case 'error':
    finalConfig.style = {
      background: "linear-gradient(to left, #912020, #e51515)",
    }
    break
  case 'warning':
    finalConfig.style = {
      background: "linear-gradient(to left, #918820, #e5c215)",
    }
    break
  default:
    finalConfig.style = {
      background: "linear-gradient(to left, #204191, #1597E5)",
    }
  }

  finalConfig.style = {
    ...finalConfig.style,
    fontSize: '1.2em',
    borderRadius: '0.4em',
    fontWeight: '500'
  }

  console.log(finalConfig)

  Toastify(finalConfig).showToast();
}