import React, { useEffect } from 'react'
import { toggleMovileMenu } from '../../utils/toggleMovileMenu'
import * as Icons from 'react-feather'


export const MovileMenu = () => {
  
  useEffect(() => {
    if (document.body.classList.contains('navigation-open')) {
      console.log()
    }
    
  }, [])
  
  return (
    <div className='navigationToggleButton'>
      <button onClick={toggleMovileMenu} className="btn mobile-navigation-button d-xl-none d-inline">
        <Icons.Menu/>
      </button>
    </div>
  )
}