export default [
  {
    target: '#menu-1',
    title: 'menus',
    content: 'Veamos un breve repaso de los menús',
    position: 'bottom-right',
    isFixed: false,
    locale: { 
      next: 'Siguiente',
      back: 'Atrás',
      last: 'Finalizar'
    },
  },
  {
    target: '#menu-2',
    title: 'Estadísticas',
    content: 'Aquí tendrás tus resultados, con nuestros tableros de analítica puedes tomar excelentes decisiones respecto a tu Bot',
    position: 'bottom-right',
    isFixed: false,
    locale: { 
      next: 'Siguiente',
      back: 'Atrás',
      last: 'Finalizar'
    },
  },
  {
    target: '#menu-3',
    title: 'Live-Chat de soporte',
    content: '',
    position: 'bottom-right',
    isFixed: false,
    locale: { 
      next: 'Siguiente',
      back: 'Atrás',
      last: 'Finalizar'
    },
  },
  {
    target: '#menu-4',
    title: 'Campañas',
    content: '',
    position: 'bottom-right',
    isFixed: false,
    locale: { 
      next: 'Siguiente',
      back: 'Atrás',
      last: 'Finalizar'
    },
  },
  {
    target: '#menu-5',
    title: 'Plantillas',
    content: 'Aquí encontrarás todas las categorías y plantillas que quieras usar o bien construir tu bot desde cero ¡fácil y rápido!',
    position: 'bottom-right',
    isFixed: false,
    locale: { 
      next: 'Siguiente',
      back: 'Atrás',
      last: 'Finalizar'
    },
  },
  {
    target: '#menu-6',
    title: 'Constructor de bots',
    content: 'Aquí puedes crear increíbles chatbots, ¡fácil y rápido!',
    position: 'bottom-right',
    isFixed: false,
    locale: { 
      next: 'Siguiente',
      back: 'Atrás',
      last: 'Finalizar'
    },
  },
]