/* eslint-disable */
const improveBotRoute = process.env.REACT_APP_IMPROVEBOT_ROUTE || 'https://improvebot.krino.ai/'
/* const improveBotRoute = process.env.REACT_APP_IMPROVEBOT_ROUTE || 'https://improvebot.krino.tech/' */

//const apisBackendRoute = process.env.REACT_APP_APIS_BACKEND_ROUTE || 'https://backend.krino.ai/'
const apisBackendRoute = process.env.REACT_APP_APIS_BACKEND_ROUTE || 'https://backend.krino.ai/'
/* const apisBackendRoute = process.env.REACT_APP_APIS_BACKEND_ROUTE || 'https://backend.krino.tech/' */

const chatCommerce = process.env.REACT_APP_CHAT_COMMERCE_ROUTE || 'https://chatcommerce.krino.ai/'

const engineRoute = process.env.REACT_APP_ENGINE_ROUTE || 'https://enginef.krino.ai/'
/* const engineRoute = process.env.REACT_APP_ENGINE_ROUTE || 'https://engineprod-4edwgq22fa-ue.a.run.app/' */

const socketsRoute =  process.env.REACT_APP_SOCKETS_ROUTE || 'https://socket.krino.xyz/'
/* const socketsRoute =  process.env.REACT_APP_SOCKETS_ROUTE || 'https://sockets.krino.tech/' */

const followUpRoute = process.env.REACT_APP_FOLLOWUP_ROUTE || 'https://follow-up.krino.ai/'
/* const followUpRoute = process.env.REACT_APP_FOLLOWUP_ROUTE || 'https://follow-up.krino.xyz/' */

const engineGPTRoute = process.env.REACT_APP_ENGINE_GPT_ROUTE || 'https://gpt-demo-prod-4edwgq22fa-ue.a.run.app/'

// console.log({improveBotRoute,apisBackendRoute,engineRoute,socketsRoute})

// prod
// https://improvebot.krino.ai/
// https://backend.krino.ai/botbuilder/
// https://tfn.krino.ai/
// https://socket-improve.krino.ai/

// testing
// https://improvebot.krino.xyz/
// https://backend.krino.xyz/
// https://engine.krino.xyz/
// https://socket.krino.xyz/

// utiliza las variables de entorno para conectarse a urls dinamicamente
export const apiUrl = (url, type = 'improve') => {
  let baseUrl = ''

  if (url[0] === '/') {
    url = url.slice(1)
  }

  if (type === 'improve') {
    baseUrl = improveBotRoute
  } else if (type === 'apisBackend') {
    console.log('url',url)
    baseUrl = apisBackendRoute
  } else if (type === 'engine') {
    baseUrl = engineRoute
  } else if (type === 'socket') {
    baseUrl = socketsRoute
  } else if (type === 'followUp') {
    baseUrl = followUpRoute
  } else if (type === 'engineGPT') {
    baseUrl = engineGPTRoute
  } else if (type === 'chatCommerce') {
    baseUrl = chatCommerce
  } else {
    console.error(`Para la ruta ${url} no se especificó un tipo válido`)
  }
  
  const finalUrl = baseUrl + url
  console.log('finalUrl',finalUrl)
  return finalUrl
}

