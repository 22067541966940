const initialState = {
  token:  window.localStorage.getItem('improve-bot-token') || null,
  isKrinoClient: false,
  userData: {}
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case '@user/login':
    return {...state, token: action.token}
  case '@user/logout':
    return {...state, token: null, userData: {}}
  case '@user/krinoClient':
    return {...state, isKrinoClient: action.isKrino}
  case '@user/userData':
    return {...state, userData: action.payload}
  case '@user/updateUserData':
    return {...state, userData: {...state.userData, ...action.payload}}
  default: 
    return state
  }
}

export default userReducer 