const initialState = {
  isDarkTheme: false
}

const themeReducer = (state = initialState,  action) => {
  switch (action.type) {
  case 'toggel_Theme':
    return {...state, isDarkTheme: !state.isDarkTheme}
  default: 
    return state
  }
  
}

export default themeReducer