import axios from 'axios'
import { getToken } from './getToken'
import { apiUrl } from './utils/apiUrl'

const baseUrl = apiUrl(`03a35a7c2bb5961c20d1441eabbd2d4458e73214a07412f9aca863807fdbdf53/${getToken()}/`)
// const url = `https://improvebot.krino.ai/03a35a7c2bb5961c20d1441eabbd2d4458e73214a07412f9aca863807fdbdf53/${getToken()}/` 

export const getKrinoClientValidation = async () => {
  const { data } = await axios.get(baseUrl)
  // console.log(data)
  const valid = data.permiso === 1 || data.permiso === 2 
  return { valid, permiso: data.permiso}
}