import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('a4c9e16e4db1afd8c1d306875b64e87956c6df54481d3eba9f19ee79558ff3f9')
// const baseUrl = 'https://improvebot.krino.ai/a4c9e16e4db1afd8c1d306875b64e87956c6df54481d3eba9f19ee79558ff3f9'

export const addDashboardToClient = async (link, clientId) => {
  const { data } = await axios.post(baseUrl, {
    link,
    cliente: clientId
  },
  {
    headers: {
      token: getToken()
    }
  })

  return data
}