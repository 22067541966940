import { getToken } from "../services/getToken"
import jwt_decode from "jwt-decode";

export const isTokenExpired = (hours = 0) => {
  const token = getToken()
  if (token === null) return false
  try {
    const { exp } = jwt_decode(getToken())
    const actualDate = new Date()
    actualDate.setHours(actualDate.getHours() + hours)

    return actualDate.getTime() > exp * 1000

  } catch (error) {
    console.error('error el decodificar token')
    return true
  }
} 