import { isChatUnique } from "../../utils/isChatUnique"
const initialState = []

const chatsOnHoldReducer = (state = initialState, action) => {
  switch(action.type) {
  case 'replaceChatsOnHold': 
    return action.chats
  case 'addChatOnHold':
    return isChatUnique(state, action.chat) ? [...state, action.chat] : state
  case 'removeChatOnHold':
    return state.filter(chat => chat.chat !== action.chat.chat) 
  default:
    return state
  }
}

export default chatsOnHoldReducer