const initialState = []

const botBuilderNotes = (state = initialState, action) => {
  switch(action.type) {
  case 'botBuilder/note/add':
    return [...state, action.element]
  case 'botBuilder/notes/add': 
    return [...state, ...action.elements]
  case 'botBuilder/note/delete':
    return state.filter((item) => {
      const id = `${item.id}` 
      return id !== action.id
    })
  case 'botBuilder/note/update':
    return state.map(item => {
      if (item.id === action.element.id) {
        return action.element
      }
      return item
    })
  case 'botBuilder/note/style':
    return state.map(item => {
      if (item.id === action.id) {
        item.data.style = action.style
      }
      return item
    })
  case 'botBuilder/note/toggleMovement':
    return state.map(item => {
      const id = `${item.id}` 
      if (item.id === id) {
        item.draggable = !item.draggable
      }
      return item
    })
  case 'botBuilder/notes/removeAll':
    return initialState
  case 'botBuilder/notes/replaceAll':
    return action.elements
  default: 
    return state
  }
}

export default botBuilderNotes