import axios from 'axios'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('notificaciones/notificacion_correo_ejecutivo','chatCommerce')

export const sendErrorLog = async ({message = '', reporter = '', href = '', payload_sent='', errorDetails='', api=''}) => {
  const payload = {
    body: {
      ejecutivos: ['jmansilla@krino.cl','esteban@krino.cl','david@krino.cl','andresg@krino.cl','diego.martinez@krino.cl'],
      ejecutivos_cc: [], 
      template_id: 'd-b252fd5c6d0745829715619782bfa1ed',
      variables_dinamicas: {
        nombre: reporter, 
        payload: payload_sent, 
        errorDetails: errorDetails,
        error_message: message,
        origin:href,
        api: api,
      },
      from_email: 'developers@krino.cl',
      contenido_mail: message,
      asunto_email: `Error Report: ${href}`
    }
  };

  const { data } = await axios.post(baseUrl, payload, {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'User-Agent': 'YourAppName' // Cambia esto por el nombre de tu aplicación si es necesario
    }
  });

  return data;
};