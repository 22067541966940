const initialState = {
  selectedChat: {}
}

const selectedChatReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SELECTED_CHAT':
    return {...state, selectedChat: action.chat};
  default:
    return state
  }
  
};

export default selectedChatReducer;
