import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  ModalHeader,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import classnames from 'classnames'
import checkImg from '../../assets/img/checkCircle.png'
import errorImg from '../../assets/img/errorCircle.png'

import { Link } from 'react-router-dom'
import { isSuscriptionActive } from '../../services/payment/isSuscriptionActive'
import { getSuscription } from '../../services/payment/getSuscription'
import { cancelSuscription } from '../../services/payment/cancelSuscription'
import { inviteToKriator } from '../../services/login/inviteToKriator'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
const MySwal = withReactContent(Swal)

function EditProfileModal(props) {

  const [activeTab, setActiveTab] = useState('1')
  const [suscriptionActive, setSuscriptionActive] = useState(false) 
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [inviteData, setInviteData] = useState({
    email: ''
  })

  const handleInviteDataChange = (event) => {
    const { value, name } = event.target
    const newData = {...inviteData}
    newData[name] = value
    setInviteData(newData)
  }

  const inviteParner = async () => {
    try {
      await inviteToKriator(inviteData.email)
      MySwal.fire({
        icon: 'success',
        html: (
          `
            <div>
              <p>Invitaste a un compañero exitosamente</p>
            </div>
          `
        ), 
        confirmButtonText: 'volver',
      })
    } catch (error) {
      const status = error.response ? error.response.status : null
      let html = 'Ocurrió un error inesperado'

      if (status === 450) {
        html = 'Este usuario ya está registrado'
      }

      MySwal.fire({
        icon: "error",
        title: "Error",
        html
      })
    }
  }


  const handleCancelsuscription = async () => {
    const { isConfirmed } = await MySwal.fire({
      title: `¿Estás seguro de que quieres cancelar tu suscripción?`,
      text: 'Si tienes alguna duda antes de cancelar tu suscripción escríbele a nuestro equipo de soporte.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#cc3e34',
      cancelButtonColor: '#1597E5',
      confirmButtonText: 'Cancelar suscripción',
      cancelButtonText: 'volver'
    })
    if (isConfirmed) {
      try {
        const {sub} = await getSuscription()
        await cancelSuscription(sub)
        setSuscriptionActive(false)
      } catch(error) {
        console.log('error inesperado al cancelar suscription')
      }
    } 
  }

  useEffect(() => {
    isSuscriptionActive()
      .then(({status}) => {
        setSuscriptionActive(status)
      })
  }, [])

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} centered className="modal-dialog-zoom">
        <ModalHeader toggle={props.toggle}>
          <FeatherIcon.User className="mr-2"/> Tu perfil
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '1'})}
                onClick={() => {
                  toggle('1');
                }}
              >
                Invitar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '2'})}
                onClick={() => {
                  toggle('2');
                }}
              >
                Suscripción
              </NavLink>
            </NavItem>
          </Nav>
          <Form>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <h3>Invita a un compañero.</h3>
                <FormGroup>
                  <Label
                    for="invite_email"
                  >
                    Correo
                  </Label>
                  <Input
                    required
                    onChange={handleInviteDataChange}
                    name='email'
                    type="email"
                    id="invite_email"
                    placeholder="Nombre@mail.com"
                  />
                </FormGroup>
              </TabPane>
              <TabPane tabId="2">
                {
                  suscriptionActive
                    ? (
                      <div className='PerfilModal__suscription PerfilModal__suscription--success'>
                        <img src={checkImg} alt='Imagen de éxito' />
                        <h3>Tienes una suscripción activa</h3>
                        <Dropdown isOpen={dropdownOpen} toggle={() => {setDropdownOpen(!dropdownOpen)}}>
                          <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                            aria-expanded={dropdownOpen}
                          >
                            <button type='button' className='menuButton'>
                              <FeatherIcon.Settings />
                            </button>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={handleCancelsuscription}>
                              Cancelar suscripción
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    )
                    : (
                      <div className=' PerfilModal__suscription PerfilModal__suscription--error'>
                        <img src={errorImg} alt='Imagen de error' />
                        <h3>No tienes una suscripción activa</h3>
                        <Link to='/payment/' className='krino__button'>
                          Obtener una suscripción
                        </Link>
                      </div>
                    )
                  
                }
              </TabPane>
            </TabContent>
          </Form>
        </ModalBody>
        <ModalFooter>
          {
            activeTab === '1' && (
              <Button onClick={inviteParner} color="primary">Enviar</Button>
            )
          }
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default EditProfileModal
