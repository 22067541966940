import { useEffect, useMemo } from "react"
import { useLocation, useHistory } from 'react-router-dom'

export const useValidateViewPermissions = (menus, menuTable) => {
  const location = useLocation()
  const history = useHistory()

  const validUrls = useMemo(() => {
    const urls = []
    Object.values(menuTable).forEach(item => {
      if (item.name !== 'Inicio') {
        if (item.Dropdown) {
          item.content.forEach(dropdownItem => {
            urls.push(dropdownItem.url.replaceAll('/', ''))
          })
        } else {
          urls.push(item.url.replaceAll('/', ''))

        }
      }
    });
    return urls
  }, [menuTable])

  useEffect(() => {
    if (menus.length && !menus.some(menu => location.pathname.includes(menu.url))) {
      const locationWithoutSlash = location.pathname.replaceAll('/', '')
      if (location.pathname !== '/' && validUrls.some(validUrl => locationWithoutSlash.includes(validUrl))) {
        history.push(menus[0].url)
      }
    }

  }, [location, menus, validUrls])
}