import React, { useState } from 'react'
import bugIlustration from '../assets/img/bug_ilustration.svg'
import { sendErrorLog } from '../services/external/sendErrorLog'
import { toast } from '../utils/toast'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
const MySwal = withReactContent(Swal)

const ErrorPage = ({error}) => {
  const [reportedBug, setReportedBug] = useState(false)

  const handleSendLog = () => {

    MySwal.fire({
      text:'Ingresa tu email',
      inputPlaceholder: 'nombre@mail.com',
      input: 'email',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#1597E5',
      cancelButtonColor: '#cc3e34',
      cancelButtonText: 'Cancelar',
      preConfirm: (mail) => {
        sendErrorLog({
          message: error.message,
          reporter: mail,
          href: window.location.href})
        setReportedBug(true)
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      toast({
        text: 'Su reporte se envió correctamente',
      }, 'success')
    })
  }

  return (
    <main className='errorPage'>
      <section className='errorPage__section' >
        <img
          src={bugIlustration}
          alt='bug'
          className='errorPage__img'
        />
        <h1
          className='errorPage__title'
        >
          Oops!
        </h1>
        <p
          className='errorPage__description'
        >
          Ocurrió un error inesperado en la plataforma
        </p>
        <div className='errorPage__actionContainer'>
          <a href="/" className='krino__button'>
            Volver al inicio
          </a>
          <button
            disabled={reportedBug}
            className={`krino__button ${reportedBug && 'krino__button--disable'} ml-1`}
            onClick={handleSendLog}
          >
            Reportar error
          </button>
        </div>
      </section>
    </main>
  )
  
}

export default ErrorPage