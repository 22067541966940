// Catch the  dial result if unsuccessful
// 2: "Busy"
// 3: "Not Answer"
// 4: "Fax/Modem"
// 5: "NoDialTone"
// 8: "Other"
// 10: "NoService"
// 11: "VoiceMail/Machine"
// 12: "Circut Busy"
// 13: "Cancelled"
// 90: "Rejected by provider"

const ERRORS = {
  2: "Ocupado",
  3: "sin respuesta",
  4: "Fax/Modem",
  5: "Sin tono de marcado",
  8: "Otro",
  10: "Sin servicio",
  11: "Correo de voz / Máquina",
  12: "Circuito ocupado",
  13: "Cancelado",
  90: "Rechazado por el proveedor"
}

export const getDialErrorMessage = (dialId) => {
  return ERRORS[dialId] ?? 'Desconocido'
}